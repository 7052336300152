.neusta-corporate-teaser {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.neusta-corporate-teaser > a {
    display: block;
    text-decoration: none;
}

.neusta-corporate-teaser .content {
    padding: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 100%);

    @media only screen and (min-width: 1024px) {
        padding: 40px;
    }
}

.neusta-corporate-teaser .headline {
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: block;
}

.neusta-corporate-teaser .title {
    color: #fff;
    font-family: NorthernSoul, sans-serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 45px;
    display: block;
    margin-top: 10px;

    @media only screen and (min-width: 1024px) {
        font-size: 50px;
        line-height: 60px;
    }
}

.neusta-corporate-teaser .media-wrapper {
    height: 450px;
    width: 100%;
    position: relative;

    @media only screen and (min-width: 1024px) {
        height: 550px;
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }
}

.neusta-corporate-teaser.sustainability {
    .content {
        position: unset;
        background: transparent;
        padding: 0;
        justify-content: center;
        height: unset;
    }

   .headline {
        color: #646363;
        text-align: center;
        font-size: 30px;
        padding: 48px 0;
        font-weight: bold;

       @media screen and (max-width: 1024px) {
            text-align: left;
           padding: 24px 0;
       }
    }


}
